import { cloneElement, forwardRef, ReactElement } from 'react';

import { ChangeHandler, Control } from 'react-hook-form';

import { Error } from '../Error';
import { Label, LabelProps } from '../Label';
import { isControlled } from './index.utils';

interface FieldProps {
  children: ReactElement;
  className?: string;
  control?: Control<any>;
  error?: { message?: string };
  label?: LabelProps['children'];
  labelClassname?: string;
  name?: string;
  onBlur?: ChangeHandler;
  onChange?: ChangeHandler;
}

/*
 * Field component is basic form element that renders label, input and validation error
 *
 * Most of the props are coming from useForm's register
 * Provide input as a child and register the Field component
 * */
export const Field = forwardRef<any, FieldProps>(
  (
    {
      children,
      className,
      label,
      labelClassname,
      name,
      onChange,
      onBlur,
      error,
      control,
    },
    ref: any,
  ) => {
    const id = name;
    return (
      <div className={className}>
        {label && (
          <Label htmlFor={id} className={labelClassname}>
            {label}
          </Label>
        )}
        {cloneElement(
          children,
          isControlled(children)
            ? {
                ...children.props,
                control,
                error,
              }
            : {
                ...children.props,
                id,
                name,
                onChange,
                onBlur,
                error,
                ref,
              },
        )}
        <Error value={error} />
      </div>
    );
  },
);

Field.displayName = 'Field';
