import React, { ReactNode } from 'react';

import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { useRouter } from 'next/router';
import { QueryClient, QueryClientProvider } from 'react-query';

import { auth0ClientId, auth0Domain } from 'utils/env';
import { redirectUri } from 'utils/env';

import { UserContextProvider } from './UserContext';

interface AppProviderProps {
  children?: ReactNode;
}

const queryClient = new QueryClient();

export const AppProvider = ({ children }: AppProviderProps) => {
  const router = useRouter();

  // Redirect user after signing in
  // https://community.auth0.com/t/withauthenticationrequires-returns-user-to-another-route-after-page-refresh/62227/3
  const onRedirectCallback = (appState?: AppState) => {
    router.push(appState?.returnTo || window.location.pathname);
  };
  return (
    <Auth0Provider
      clientId={auth0ClientId}
      cacheLocation="localstorage" // required for sharing session between tabs
      domain={auth0Domain}
      onRedirectCallback={onRedirectCallback}
      redirectUri={redirectUri}
      useRefreshTokens
    >
      <QueryClientProvider client={queryClient}>
        <UserContextProvider>{children}</UserContextProvider>
      </QueryClientProvider>
    </Auth0Provider>
  );
};
