import {
  ReactNode,
  ElementType,
  ReactElement,
  isValidElement,
  Fragment,
} from 'react';

export const ofType = <Props,>(
  child: ReactNode,
  element: ElementType<Props>,
): child is ReactElement<Props> =>
  isValidElement(child) && child.type === element;

export const replaceJSX = (value: string, find: string, replace: ReactNode) =>
  value.split(find).map((text, index, array) =>
    index === array.length - 1 ? (
      text
    ) : (
      <Fragment key={text}>
        {text}
        {replace}
      </Fragment>
    ),
  );
