export type Size = 'sm' | 'lg';

export const sizeClasses: Record<Size, { input: string; label: string }> = {
  sm: {
    input: 'w-4 h-4',
    label: 'ml-2 text-sm',
  },
  lg: {
    input: 'w-6 h-6',
    label: 'ml-3 text-base',
  },
};
