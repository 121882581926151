import { API, graphqlOperation } from 'aws-amplify';

export const gqlOp = <ResultType extends {}, VariablesType extends {} = {}>(
  query: string,
  variables?: VariablesType,
) => {
  return async () => {
    const { data } = (await API.graphql(
      graphqlOperation(query, variables),
    )) as unknown as {
      data: ResultType;
    };
    return data;
  };
};
