import { HTMLProps, useState } from 'react';

import { Combobox as BaseCombobox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { ErrorOption } from 'react-hook-form';

interface Option {
  label: string;
  value: string;
}

export interface InputTextProps
  extends Omit<HTMLProps<HTMLInputElement>, 'onChange'> {
  onChange: (value: string[]) => void;
  options: Option[];
  selectedValue: any;
  autoFocus?: boolean;
  error?: ErrorOption;
  indicateErrorIcon?: boolean;
}

export const Combobox = ({
  options,
  className,
  error,
  label,
  onChange,
  selectedValue,
}: InputTextProps) => {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <BaseCombobox as="div" value={selectedValue} onChange={onChange}>
      <BaseCombobox.Label className="block text-sm font-medium text-gray-700">
        {label}
      </BaseCombobox.Label>
      <div className="relative mt-1">
        <BaseCombobox.Input
          className={classNames(
            'w-full focus:ring-black shadow-sm focus:border-black text-sm md:text-base leading-6 font-normal border-gray-300 rounded-[32px] py-[9px] pr-3',
            className,
            error && 'border-red-300 text-red-900',
          )}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(option: Option) => option?.label}
        />
        <BaseCombobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </BaseCombobox.Button>

        {filteredOptions.length > 0 && (
          <BaseCombobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions.map((option, key) => (
              <BaseCombobox.Option
                key={key}
                value={option}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-[#FFD600] text-black' : 'text-gray-900',
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        'block truncate',
                        selected && 'font-semibold',
                      )}
                    >
                      {option.label}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-black' : 'text-[#FFD600]',
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </BaseCombobox.Option>
            ))}
          </BaseCombobox.Options>
        )}
      </div>
    </BaseCombobox>
  );
};

Combobox.displayName = 'Combobox';
