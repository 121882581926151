export const appSync = {
  dev: {
    aws_appsync_graphqlEndpoint:
      'https://amhxxfeabzf55diertgot25rxm.appsync-api.us-west-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-west-2',
    aws_appsync_authenticationType: 'AWS_IAM',
  },
  prod: {
    aws_appsync_graphqlEndpoint:
      'https://fb46l2agybhd7fym4myvmpqssm.appsync-api.us-west-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-west-2',
    aws_appsync_authenticationType: 'AWS_IAM',
  },
};
