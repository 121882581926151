import { useCallback } from 'react';

import { evaluateInitialState } from '@/helpers/misc';
import { InitialState } from '@/helpers/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { get } from 'lodash';
import { useForm as useFormHook } from 'react-hook-form';
import type {
  FieldError,
  FieldValues,
  UseFormProps,
} from 'react-hook-form/dist/types';
import * as Yup from 'yup';
import Lazy from 'yup/lib/Lazy';

/*
 * Wrapper over react-hook-form's useForm
 *
 * Features:
 *  - injects error while registering to automatically display correct state in Form.Field component
 *  - provides default resolver, reValidateMode and mode props
 *  - provides isSubmitDisabled flag
 * */
export const useForm = <TFieldValues extends FieldValues = FieldValues>({
  formSchema,
  ...props
}: UseFormProps<TFieldValues> & {
  formSchema: InitialState<Yup.AnyObjectSchema | Lazy<any>>;
}) => {
  const form = useFormHook({
    resolver: yupResolver(evaluateInitialState(formSchema)),
    reValidateMode: 'onChange',
    mode: 'all',
    ...props,
  });

  const {
    formState: { errors, isValid, isDirty },
    control,
    register: formRegister,
  } = form;

  const register = useCallback(
    (...args: Parameters<typeof formRegister>) => {
      const [path] = args;
      const error = get(errors, path) as FieldError | undefined;
      return {
        ...formRegister(...args),
        error,
      };
    },
    // form.register never changes, we need to depend on errors only
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors],
  );

  const registerControl = useCallback(
    (path: Parameters<typeof formRegister>[0]) => {
      const error = get(errors, path) as FieldError | undefined;
      return {
        control,
        error,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors, control],
  );

  const isSubmitDisabled = !isValid || !isDirty;

  return {
    ...form,
    isSubmitDisabled,
    register,
    registerControl,
  };
};
