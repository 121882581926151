import { appSync } from 'appsync-exports';

export const appEnv = (process.env.NEXT_PUBLIC_ENV as 'dev' | 'prod') ?? 'dev';
export const isProd = appEnv === 'prod';
export const publicUrl = process.env.NEXT_PUBLIC_URL as string;
export const alchemyId = process.env.NEXT_PUBLIC_ALCHEMY_ID as string;
export const walletConnectId = process.env
  .NEXT_PUBLIC_WALLET_CONNECT_ID as string;
export const ourPaymentAddress = process.env
  .NEXT_PUBLIC_PAYMENT_ADDRESS as string;
export const identityPoolId = process.env
  .NEXT_PUBLIC_IDENTITY_POOL_ID as string;
export const auth0Domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string;
export const auth0ClientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string;
export const openidProvider = process.env.NEXT_PUBLIC_OPENID_PROVIDER as string;
export const redirectUri = process.env.NEXT_PUBLIC_URL as string;
export const appSyncConfig = isProd ? appSync.prod : appSync.dev;
export const landingPageURL = process.env.NEXT_PUBLIC_URL_LP as string;
// https://developers.circle.com/developer/docs/usdc-on-testnet
export const USDCContract =
  appEnv === 'dev'
    ? '0x07865c6E87B9F70255377e024ace6630C1Eaa37F'
    : '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48';
