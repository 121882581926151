import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Auth, Amplify } from 'aws-amplify';
import { useRouter } from 'next/router';

import {
  CompanyUser,
  CompanyUserStatusType,
  useCompanyUserGetQuery,
  useCompanyUserStatusGetQuery,
} from 'generated/graphql';
import { openidProvider } from 'utils/env';
import { User } from 'utils/types';

type Context = {
  isLoadingRedirect: boolean;
  refreshCompanyUser: () => Promise<any>;
  user: User;
  companyUser?: CompanyUser;
};

const UserContext = createContext<Context>({} as Context);

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const [user, setUser] = useState<User>({});
  const [companyUser, setCompanyUser] = useState<CompanyUser>();
  const router = useRouter();
  const [isLoadingRedirect, setLoadingRedirect] = useState(false);

  // here we are listen to different events happening inside the Next.js Router,
  // but at the same time we check user auth effect and control the loading state
  useEffect(() => {
    const handleStart = () => setLoadingRedirect(true);

    const handleComplete = () => setLoadingRedirect(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  });

  const { refetch } = useCompanyUserStatusGetQuery(
    {
      id: user.id ?? '',
      email: user.email ?? '',
    },
    {
      onSuccess: (data) => {
        const status = data?.companyUserStatusGet?.status;
        switch (status) {
          case CompanyUserStatusType.New:
          case CompanyUserStatusType.Invited:
            router.push('/onboarding/account');
            break;
          case CompanyUserStatusType.Registered:
            setCompanyUser(
              data?.companyUserStatusGet?.companyUser as CompanyUser,
            );
            router.push('/invoices');
            break;
          case CompanyUserStatusType.RequireCompanyInfo:
            setCompanyUser(
              data?.companyUserStatusGet?.companyUser as CompanyUser,
            );
            router.push('/onboarding/company');
            break;
          case CompanyUserStatusType.NoInvitation:
            router.push('/restricted');
            break;
          default:
            break;
        }
      },
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const { refetch: refreshCompanyUser } = useCompanyUserGetQuery(
    {
      identityId: user.id ?? '',
    },
    {
      onSuccess: (data) => {
        setCompanyUser(data?.companyUserGet as CompanyUser);
      },
    },
  );

  useEffect(() => {
    if (user.id && user.email) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const idtoken = await getIdTokenClaims();
        // shouldn't happen unless there's a severe issue
        if (!idtoken) {
          setUser({});
          return;
        }
        await Amplify.Credentials.set(
          {
            provider: openidProvider,
            token: idtoken.__raw,
            user: { name: idtoken.name, email: idtoken.email },
            expires_at: idtoken.exp,
          },
          'federation',
        );
        const currentUser = await Auth.currentAuthenticatedUser();
        setUser(currentUser);
      } catch (error) {
        setUser({});
      }
    };

    fetchAccessToken();
  }, [getIdTokenClaims, isAuthenticated]);

  const refetchCompanyUser = async () => {
    await refetch();
  };

  return (
    <UserContext.Provider
      value={{ user, companyUser, isLoadingRedirect, refreshCompanyUser }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useCurrentUser = (): Context => useContext(UserContext);
