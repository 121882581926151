import { forwardRef, HTMLProps, Ref } from 'react';

import { ExclamationCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { ErrorOption } from 'react-hook-form';

export interface TextAreaProps extends HTMLProps<HTMLTextAreaElement> {
  autoFocus?: boolean;
  error?: ErrorOption;
  indicateErrorIcon?: boolean;
}

export const TextArea = forwardRef(
  (
    {
      onChange = () => {},
      className,
      error,
      indicateErrorIcon = true,
      ...props
    }: TextAreaProps,
    ref: Ref<HTMLTextAreaElement>,
  ) => {
    return (
      <div className="relative mt-1">
        <textarea
          className={classNames(
            className,
            'w-full focus:ring-black focus:border-black text-sm md:text-base leading-6 font-normal border-gray-300 rounded-[32px] py-[10px] px-6',
            error && 'border-red-300 text-red-900',
            props.disabled && 'cursor-not-allowed',
          )}
          onChange={onChange}
          ref={ref}
          style={{ resize: 'vertical', minHeight: 'min-content' }} // Add minHeight based on your preferred height
          {...props}
        />

        {indicateErrorIcon && error && (
          <div
            className="w-6 h-6 absolute top-[9px] right-3 text-red-500"
            role="presentation"
          >
            <ExclamationCircleIcon />
          </div>
        )}
      </div>
    );
  },
);

TextArea.displayName = 'TextArea';
