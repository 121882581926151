import { forwardRef, HTMLProps, ReactNode, Ref, useCallback } from 'react';

import { ExclamationCircleIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { ErrorOption } from 'react-hook-form';

import { currency } from '@/helpers/masks';

export interface InputNumberProps extends HTMLProps<HTMLInputElement> {
  autoFocus?: boolean;
  error?: ErrorOption;
  hasCurrency?: boolean;
  indicateErrorIcon?: boolean;
  leadingIcon?: ReactNode;
  max?: number;
  min?: number;
  trailingIcon?: ReactNode;
}

export const NumberInput = forwardRef(
  (
    {
      onChange = () => {},
      className,
      type = 'number',
      error,
      indicateErrorIcon = true,
      hasCurrency,
      leadingIcon,
      trailingIcon,
      min,
      max,
      ...props
    }: InputNumberProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const handleKeyUp = useCallback(
      (e: React.FormEvent<HTMLInputElement>) => {
        if (hasCurrency) currency(e);
      },
      [hasCurrency],
    );

    return (
      <div className="relative mt-1">
        {leadingIcon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-6">
            {leadingIcon}
          </div>
        )}

        <input
          className={classNames(
            className,
            'w-full focus:ring-black focus:border-black text-sm md:text-base leading-6 font-normal border-gray-300 rounded-[32px] py-[9px] pr-3',
            leadingIcon ? 'pl-[52px]' : 'pl-6',
            error && 'border-red-300 text-red-900',
            props.disabled && 'cursor-not-allowed',
          )}
          type={type}
          onChange={onChange}
          onKeyUp={handleKeyUp}
          min={min}
          max={max}
          ref={ref}
          {...props}
        />

        {trailingIcon && !error && (
          <div
            className="absolute inset-y-0 right-0 flex items-center pr-4"
            role="presentation"
          >
            {trailingIcon}
          </div>
        )}

        {indicateErrorIcon && error && (
          <div
            className="w-6 h-6 absolute top-[9px] right-3 text-red-500"
            role="presentation"
          >
            <ExclamationCircleIcon />
          </div>
        )}
      </div>
    );
  },
);

NumberInput.displayName = 'Number';
