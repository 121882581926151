import { ReactNode } from 'react';

import { Controller } from 'react-hook-form';

import { ofType } from '@/utils/react';

const controlledComponents = [Controller] as const;

export const isControlled = (children: ReactNode) =>
  controlledComponents.some((component) => ofType<any>(children, component));
