import { isFunction } from 'lodash';

export const strategy = <T extends string | number | symbol, F>(
  record: Record<T, F>,
  status: T,
) => record[status];

export const notImplemented: any = () => {
  alert('Not implemented');
};

type MergeClassNamesFn = {
  <O1, O2>(o1: O1, o2: O2): O1 & O2;
  <O1, O2, O3>(o1: O1, o2: O2, o3: O3): O1 & O2 & O3;
  <O1, O2, O3, O4>(o1: O1, o2: O2, o3: O4): O1 & O2 & O3 & O4;
};

export const mergeClassNames: MergeClassNamesFn = (...objects: any) => {
  const result = {};
  for (const object of objects) {
    Object.entries(object).forEach(([key, value]) => {
      const oldValue = result[key];
      if (oldValue) {
        result[key] = `${oldValue} ${value}`;
      } else {
        result[key] = value;
      }
    });
  }
  return result;
};

export const evaluateInitialState = (state: any) =>
  isFunction(state) ? state() : state;
