import { forwardRef, HTMLProps, Ref } from 'react';

import classNames from 'classnames';
import { ErrorOption } from 'react-hook-form';

export interface SelectProps extends HTMLProps<HTMLSelectElement> {
  options: {
    label: string;
    value: string;
  }[];
  className?: string;
  error?: ErrorOption;
}

export const Select = forwardRef(
  (
    { options, className, error, ...props }: SelectProps,
    ref: Ref<HTMLSelectElement>,
  ) => (
    <div className="mt-1">
      <select
        {...props}
        ref={ref}
        className={classNames(
          className,
          'w-full focus:ring-black focus:border-black text-sm md:text-base leading-6 font-normal border-gray-300 rounded-[32px]',
          error && 'border-red-300 text-red-900',
          props.disabled && 'cursor-not-allowed',
        )}
      >
        {options &&
          options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
    </div>
  ),
);

Select.displayName = 'Select';
