import { forwardRef, HTMLProps, ReactNode, Ref } from 'react';

import classNames from 'classnames';

import { Size, sizeClasses } from './index.constants';

interface CheckboxProps extends Omit<HTMLProps<HTMLInputElement>, 'size'> {
  autoFocus?: boolean;
  children?: ReactNode;
  size?: Size;
}

export const Checkbox = forwardRef(
  (
    {
      children,
      id,
      size = 'sm',
      className = 'items-center',
      ...props
    }: CheckboxProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const classes = sizeClasses[size];
    return (
      <div className={classNames('flex', className)}>
        <input
          id={id}
          type="checkbox"
          className={classNames(
            'text-indigo-600 bg-white rounded border-gray-300 focus:ring-indigo-500 focus:ring-2',
            classes.input,
          )}
          ref={ref}
          {...props}
        />
        {children && (
          <label htmlFor={id} className={classes.label}>
            {children}
          </label>
        )}
      </div>
    );
  },
);

Checkbox.displayName = 'Checkbox';
