import '../styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import 'flowbite';
import '@rainbow-me/rainbowkit/styles.css';

import { useEffect, useState } from 'react';

import { Field } from '@/noxx-ui/components/Form';
import { Checkbox } from '@/noxx-ui/components/Input';
import { Amplify } from 'aws-amplify';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import { useLocalStorage } from 'usehooks-ts';
import {
  RainbowKitProvider,
  getDefaultWallets,
  connectorsForWallets,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';

import * as yup from 'yup';

import { AppProvider } from 'contexts';
import { useForm } from 'hooks/useForm';
import {
  alchemyId,
  appSyncConfig,
  identityPoolId,
  appEnv,
  walletConnectId,
} from 'utils/env';
import { selectedChain } from 'utils/selectedChain';
import { Cookie } from 'utils/types';

import awsconfig from '../aws-exports';

// rainbow kit setup
const { wallets } = getDefaultWallets({
  appName: `Noxx${appEnv === 'dev' ? ' Dev' : ''}`,
  projectId: walletConnectId,
  chains: [selectedChain],
});

const connectors = connectorsForWallets([...wallets]);

// Wagmi client
const { publicClient } = configureChains(
  [selectedChain],
  [alchemyProvider({ apiKey: alchemyId })],
);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

Amplify.configure({
  aws_project_region: awsconfig.aws_project_region,
  ...appSyncConfig,
  Auth: {
    identityPoolId,
    region: awsconfig.aws_project_region,
  },
});

const formSchema = yup
  .object({
    necessary: yup.bool().oneOf([true]),
    statistics: yup.bool().oneOf([true, false]),
  })
  .required();

const CookieBanner = dynamic(
  () => import('@/noxx-ui/components/CookieBanner'),
  { ssr: false },
);

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [_cookies, _setCookie, removeCookie] = useCookies();
  const [cookieConsent, setCookieConsent] = useLocalStorage<Cookie>(
    'cookieConsentCompany',
    {
      consent: false,
      statistics: true,
    },
  );
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
  }, []);

  useEffect(() => {
    const { NEXT_PUBLIC_GOOGLE_ANALYTICS } = process.env;

    if (cookieConsent.statistics && NEXT_PUBLIC_GOOGLE_ANALYTICS) {
      ReactGA.initialize(NEXT_PUBLIC_GOOGLE_ANALYTICS);

      const handleRouteChange = (url: string) => {
        ReactGA.send({ hitType: 'pageView', url });
      };

      //When the component is mounted, subscribe to router changes
      //and log those page views
      router.events.on('routeChangeComplete', handleRouteChange);

      // If the component is unmounted, unsubscribe
      // from the event with the `off` method
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, [cookieConsent.statistics, router.events]);

  const title = appEnv === 'prod' ? 'Noxx Company' : '(dev) Noxx Company';

  const { register, handleSubmit } = useForm({
    formSchema,
    defaultValues: {
      necessary: true,
      statistics: true,
    },
  });

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    removeCookie('_ga');
    removeCookie('_gat');
    removeCookie('_gid');
  };

  const handleConfirm = handleSubmit(async (values) => {
    const { statistics } = values;
    if (!statistics) {
      handleDeclineCookie();
    }

    setCookieConsent({
      consent: true,
      statistics,
    });
  });

  return (
    <>
      {!cookieConsent.consent && (
        <CookieBanner
          description={
            <>
              By clicking “Accept cookies” or continuing to browse this website,
              you agree Noxx can store cookies on your device. You can learn
              more about how we use cookies and set cookie preferences in
              Settings.
              <a
                href="https://knotinc.notion.site/Privacy-Policy-d1f467096d0e4145b63ebff6b90312f4"
                target="_blank"
                className="underline text-blue-500 ml-1 mr-1"
                rel="noreferrer"
              >
                Privacy Policy.
              </a>
            </>
          }
          onAccept={handleConfirm}
        >
          <form>
            <Field {...register('necessary')}>
              <Checkbox size="lg" disabled={true}>
                <p className="text-sm leading-8 font-normal max-w-[815px] text-gray-500 ml-3">
                  <span className="text-white">Necessary</span> These are
                  cookies that are required for the operation of our website.
                  They include, for example, cookies that enable website
                  visitors to log into secure areas of our website. These
                  cookies do not store any personally identifiable information.
                </p>
              </Checkbox>
            </Field>
            <Field {...register('statistics')}>
              <Checkbox size="lg">
                <p className="text-sm leading-8 font-normal max-w-[815px] text-gray-500 ml-3">
                  <span className="text-white">Statistics</span> They allow us
                  to recognize and count the number of visitors and to see how
                  visitors move around our website when they are using it. This
                  helps us to improve the way our website works, for example, by
                  ensuring that users are finding what they are looking for
                  easily. These cookies do not store location/personal
                  information
                </p>
              </Checkbox>
            </Field>
          </form>
        </CookieBanner>
      )}
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <meta name="msapplication-TileColor" content="#1C1C1C" />
        <meta name="theme-color" content="#1C1C1C" />
        <title>{title}</title>
      </Head>

      {ready ? (
        <WagmiConfig config={wagmiConfig}>
          <RainbowKitProvider
            appInfo={{ appName: 'Noxx' }}
            chains={[selectedChain]}
          >
            <AppProvider>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />

              <Component {...pageProps} />
            </AppProvider>
          </RainbowKitProvider>
        </WagmiConfig>
      ) : null}
    </>
  );
}

export default App;
