import { useMutation, useQuery, useInfiniteQuery, UseMutationOptions, UseQueryOptions, UseInfiniteQueryOptions, QueryFunctionContext } from 'react-query';
import { gqlOp } from '@/helpers/api';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: any;
  AWSDateTime: any;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type Commitment = {
  __typename?: 'Commitment';
  cognitoIdentity: Scalars['String'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  filePath: Scalars['String'];
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CommitmentInput = {
  __typename?: 'CommitmentInput';
  cognitoIdentity: Scalars['String'];
  filePath: Scalars['String'];
  type: Scalars['String'];
};

export type CommitmentList = {
  __typename?: 'CommitmentList';
  items?: Maybe<Array<Maybe<Commitment>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type CommitmentUpdate = {
  __typename?: 'CommitmentUpdate';
  filePath: Scalars['String'];
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type Company = {
  __typename?: 'Company';
  address: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  ownerCompanyUserId?: Maybe<Scalars['ID']>;
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type CompanyCreateInput = {
  address: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  ownerCompanyUserId: Scalars['ID'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type CompanyInvite = {
  __typename?: 'CompanyInvite';
  /** @deprecated ID does not exist */
  companyId: Scalars['ID'];
  companyName: Scalars['String'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  /** @deprecated For field name consistency. Use `email`. */
  inviteEmail: Scalars['String'];
  inviter: Scalars['ID'];
};

export type CompanyInviteCreateInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  companyName: Scalars['String'];
  email: Scalars['String'];
  inviteEmail?: InputMaybe<Scalars['String']>;
  inviter: Scalars['ID'];
};

export type CompanyRequest = {
  __typename?: 'CompanyRequest';
  companyId: Scalars['ID'];
  contractStatus?: Maybe<ContractStatus>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  startDate?: Maybe<Scalars['AWSDateTime']>;
  talentAddress: Scalars['String'];
  talentId?: Maybe<Scalars['String']>;
  talentName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  wage?: Maybe<Scalars['Float']>;
};

export type CompanyRequestCreateInput = {
  companyId: Scalars['ID'];
  contractType?: InputMaybe<ContractType>;
  currency?: InputMaybe<CurrencyType>;
  email: Scalars['String'];
  paymentRate?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['AWSDateTime']>;
  talentAddress: Scalars['String'];
  talentId?: InputMaybe<Scalars['ID']>;
  wage: Scalars['Float'];
};

export type CompanyRequestFilterInput = {
  createdAt?: InputMaybe<CreatedAtFilterInput>;
  statuses?: InputMaybe<Array<InputMaybe<ContractStatus>>>;
};

export type CompanyRequestList = {
  __typename?: 'CompanyRequestList';
  items?: Maybe<Array<Maybe<CompanyRequestListResponse>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type CompanyRequestListResponse = {
  __typename?: 'CompanyRequestListResponse';
  companyId: Scalars['String'];
  contractStatus?: Maybe<ContractStatus>;
  contractType?: Maybe<ContractType>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  currency?: Maybe<CurrencyType>;
  email: Scalars['String'];
  id: Scalars['ID'];
  /**
   *   Deprecate paymentRate
   * @deprecated For general naming. Use `wage`.
   */
  paymentRate?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['AWSDateTime']>;
  talentAddress: Scalars['String'];
  talentId?: Maybe<Scalars['String']>;
  talentName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  wage?: Maybe<Scalars['Float']>;
};

export type CompanyRequestUpdateInput = {
  contractStatus?: InputMaybe<ContractStatus>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  talentId?: InputMaybe<Scalars['ID']>;
  talentName?: InputMaybe<Scalars['String']>;
};

export type CompanyUser = IUser & {
  __typename?: 'CompanyUser';
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  title?: Maybe<Scalars['String']>;
};

export type CompanyUserCreateInput = {
  companyId: Scalars['String'];
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  role: Role;
  title?: InputMaybe<Scalars['String']>;
};

export type CompanyUserInvite = {
  __typename?: 'CompanyUserInvite';
  companyId: Scalars['ID'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inviterName: Scalars['String'];
  role: Role;
};

export type CompanyUserInviteCreateInput = {
  companyId: Scalars['ID'];
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  inviterName: Scalars['String'];
  role: Role;
};

export type CompanyUserInviteList = {
  __typename?: 'CompanyUserInviteList';
  items?: Maybe<Array<Maybe<CompanyUserInvite>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type CompanyUserList = {
  __typename?: 'CompanyUserList';
  items?: Maybe<Array<Maybe<CompanyUser>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type CompanyUserStatus = {
  __typename?: 'CompanyUserStatus';
  companyUser?: Maybe<CompanyUser>;
  status?: Maybe<CompanyUserStatusType>;
};

export enum CompanyUserStatusType {
  Invited = 'INVITED',
  New = 'NEW',
  NoInvitation = 'NO_INVITATION',
  Registered = 'REGISTERED',
  RequireCompanyInfo = 'REQUIRE_COMPANY_INFO'
}

export type CompanyUserUpdateInput = {
  companyId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
  middleName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role>;
  title?: InputMaybe<Scalars['String']>;
};

export enum ContractStatus {
  For = 'For',
  GraphQl = 'GraphQL',
  Init = 'INIT',
  Registered = 'REGISTERED',
  Removed = 'REMOVED',
  RemovedPaid = 'REMOVED_PAID',
  RequestedVerification = 'REQUESTED_VERIFICATION',
  From = 'from',
  Initializing = 'initializing'
}

export enum ContractType {
  Grant = 'GRANT',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  Project = 'PROJECT'
}

export type CreateDocumentInput = {
  docStatus?: InputMaybe<DocumentStatus>;
  docType?: InputMaybe<DocumentType>;
  docYear?: InputMaybe<Scalars['Int']>;
  documentName?: InputMaybe<Scalars['String']>;
  talentId?: InputMaybe<Scalars['String']>;
};

export type CreateTalentInput = {
  id: Scalars['String'];
};

export type CreatedAtFilterInput = {
  between: Array<Scalars['AWSDateTime']>;
};

export enum CurrencyType {
  Usdc = 'USDC'
}

export type Dob = {
  __typename?: 'Dob';
  /**   Numerical day between 1 and 31. */
  day?: Maybe<Scalars['Int']>;
  /**   Numerical month between 1 and 12. */
  month?: Maybe<Scalars['Int']>;
  /**   The four-digit year. */
  year?: Maybe<Scalars['Int']>;
};

export type Document = {
  __typename?: 'Document';
  accountNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  docStatus?: Maybe<DocumentStatus>;
  docType?: Maybe<DocumentType>;
  docYear?: Maybe<Scalars['Int']>;
  documentName?: Maybe<Scalars['String']>;
  generatedAt?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  talentId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type DocumentList = {
  __typename?: 'DocumentList';
  items?: Maybe<Array<Maybe<Document>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum DocumentStatus {
  Failed = 'FAILED',
  Progress = 'PROGRESS',
  Succeeded = 'SUCCEEDED'
}

export enum DocumentType {
  Nec1099 = 'nec1099',
  W8 = 'w8',
  W9 = 'w9'
}

export type Education = {
  __typename?: 'Education';
  detail?: Maybe<Scalars['String']>;
  graduatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type EducationInput = {
  detail?: InputMaybe<Scalars['String']>;
  graduatedAt?: InputMaybe<Scalars['AWSDate']>;
};

export type ExpirationDate = {
  __typename?: 'ExpirationDate';
  day?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type GenerateDocumentPdfInput = {
  documentId: Scalars['ID'];
};

export type GenerateDocumentPdfResult = {
  __typename?: 'GenerateDocumentPDFResult';
  triggered?: Maybe<Scalars['Boolean']>;
};

export type GenerateVerificationCodeInput = {
  email: Scalars['String'];
  talentId: Scalars['String'];
};

export type GenerateVerificationCodeResult = {
  __typename?: 'GenerateVerificationCodeResult';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ITalentResume = {
  field?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type IUser = {
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
};

export type InviteCode = {
  __typename?: 'InviteCode';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  invitedAt?: Maybe<Scalars['AWSDateTime']>;
  invitee?: Maybe<Scalars['ID']>;
  isUsed: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type InviteCodeFilterInput = {
  isUsed?: InputMaybe<Scalars['Boolean']>;
};

export type InviteCodeList = {
  __typename?: 'InviteCodeList';
  items?: Maybe<Array<Maybe<InviteCode>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum JobRole {
  BusinessDevelopmentAndSales = 'Business_Development_And_Sales',
  Designer = 'Designer',
  Other = 'Other',
  ProductManager = 'Product_Manager',
  SoftwareEngineer = 'Software_Engineer'
}

export type KycInput = {
  address: Scalars['ID'];
  allowMultipleDocOptions?: InputMaybe<Scalars['Boolean']>;
};

export type KycCreate = {
  __typename?: 'KycCreate';
  clientSecret?: Maybe<Scalars['String']>;
  kycSessionId?: Maybe<Scalars['String']>;
};

export type KycDocument = {
  __typename?: 'KycDocument';
  address?: Maybe<Address>;
  dob?: Maybe<Dob>;
  error?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<ExpirationDate>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  issuing_country?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<KycDocumentType>;
};

/**   Same as https://stripe.com/docs/api/identity/verification_reports/object#identity_verification_report_object-options-document-allowed_types */
export enum KycDocumentType {
  DrivingLicense = 'driving_license',
  IdCard = 'id_card',
  Passport = 'passport'
}

export type KycSession = {
  __typename?: 'KycSession';
  clientSecret?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type MerkleProof = {
  __typename?: 'MerkleProof';
  leaf: Scalars['String'];
  pathIndices: Array<Scalars['Int']>;
  root: Scalars['String'];
  siblings: Array<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  companyCreate?: Maybe<Company>;
  companyInviteCreate?: Maybe<CompanyInvite>;
  companyRequestCreate?: Maybe<CompanyRequest>;
  companyRequestUpdate?: Maybe<CompanyRequest>;
  companyUserCreate?: Maybe<CompanyUser>;
  companyUserInviteCreate?: Maybe<CompanyUserInvite>;
  companyUserUpdate?: Maybe<CompanyUser>;
  createRDSTalent?: Maybe<Talent>;
  documentCreate?: Maybe<Document>;
  generateDocumentPdf?: Maybe<GenerateDocumentPdfResult>;
  generateVerificationCode?: Maybe<GenerateVerificationCodeResult>;
  mintNFT?: Maybe<NftMutationResult>;
  paymentCreate?: Maybe<Payment>;
  payrollOrderCreate?: Maybe<PayrollOrder>;
  payrollOrderUpdate?: Maybe<PayrollOrder>;
  payrollUpdate?: Maybe<Payroll>;
  payrollsCreate?: Maybe<Array<Maybe<Payroll>>>;
  payrollsDelete?: Maybe<PayrollDeleteResponse>;
  resetTalentOnboarding?: Maybe<ResetTalentOnboardingResult>;
  sessionKycCreate?: Maybe<KycCreate>;
  talentCreate?: Maybe<Talent>;
  talentProfileCreate?: Maybe<TalentProfile>;
  talentProfileUpdate?: Maybe<TalentProfile>;
  talentUpdate?: Maybe<Talent>;
  verifyCode?: Maybe<VerifyCodeResult>;
  verifyInviteCode?: Maybe<VerifyInviteCodeResult>;
  verifyProof?: Maybe<VerifyProofMutationResult>;
};


export type MutationCompanyCreateArgs = {
  input: CompanyCreateInput;
};


export type MutationCompanyInviteCreateArgs = {
  input: CompanyInviteCreateInput;
};


export type MutationCompanyRequestCreateArgs = {
  input: CompanyRequestCreateInput;
};


export type MutationCompanyRequestUpdateArgs = {
  input: CompanyRequestUpdateInput;
};


export type MutationCompanyUserCreateArgs = {
  input: CompanyUserCreateInput;
};


export type MutationCompanyUserInviteCreateArgs = {
  input: CompanyUserInviteCreateInput;
};


export type MutationCompanyUserUpdateArgs = {
  input: CompanyUserUpdateInput;
};


export type MutationCreateRdsTalentArgs = {
  input: CreateTalentInput;
};


export type MutationDocumentCreateArgs = {
  input: CreateDocumentInput;
};


export type MutationGenerateDocumentPdfArgs = {
  input: GenerateDocumentPdfInput;
};


export type MutationGenerateVerificationCodeArgs = {
  input: GenerateVerificationCodeInput;
};


export type MutationMintNftArgs = {
  input: NftInput;
};


export type MutationPaymentCreateArgs = {
  input: PaymentInput;
};


export type MutationPayrollOrderCreateArgs = {
  input: PayrollOrderCreateInput;
};


export type MutationPayrollOrderUpdateArgs = {
  input: PayrollOrderUpdateInput;
};


export type MutationPayrollUpdateArgs = {
  input: PayrollUpdateInput;
};


export type MutationPayrollsCreateArgs = {
  input: PayrollsCreateInput;
};


export type MutationPayrollsDeleteArgs = {
  input: PayrollsDeleteInput;
};


export type MutationResetTalentOnboardingArgs = {
  input: ResetTalentOnboardingInput;
};


export type MutationSessionKycCreateArgs = {
  input: KycInput;
};


export type MutationTalentCreateArgs = {
  input: TalentCreateInput;
};


export type MutationTalentProfileCreateArgs = {
  input: TalentProfileCreateInput;
};


export type MutationTalentProfileUpdateArgs = {
  input: TalentProfileUpdateInput;
};


export type MutationTalentUpdateArgs = {
  input: TalentUpdateInput;
};


export type MutationVerifyCodeArgs = {
  input: VerifyCodeInput;
};


export type MutationVerifyInviteCodeArgs = {
  input: VerifyInviteCodeInput;
};


export type MutationVerifyProofArgs = {
  input: VerifyProofInput;
};

export type NftInput = {
  address: Scalars['ID'];
};

export type NftMutationResult = {
  __typename?: 'NFTMutationResult';
  triggered?: Maybe<Scalars['Boolean']>;
};

export enum OnboardingStatus {
  Completed = 'COMPLETED',
  ComplianceUploaded = 'COMPLIANCE_UPLOADED',
  Init = 'INIT',
  KycInReview = 'KYC_IN_REVIEW',
  KycVerified = 'KYC_VERIFIED',
  NftFailed = 'NFT_FAILED',
  NftIssued = 'NFT_ISSUED',
  NftSubmitted = 'NFT_SUBMITTED',
  PfpDone = 'PFP_DONE',
  PreferencesConfirmed = 'PREFERENCES_CONFIRMED',
  PseudonymConfirmed = 'PSEUDONYM_CONFIRMED',
  ResumeCompleted = 'RESUME_COMPLETED',
  TermAgreed = 'TERM_AGREED',
  ZkpSubmitted = 'ZKP_SUBMITTED',
  ZkpVerified = 'ZKP_VERIFIED',
  ZkpVerifyFailed = 'ZKP_VERIFY_FAILED'
}

export type Payment = {
  __typename?: 'Payment';
  companyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['ID']>;
  paidAt?: Maybe<Scalars['AWSDateTime']>;
  paidCurrency?: Maybe<CurrencyType>;
  paidTalentId?: Maybe<Scalars['String']>;
  payerCompanyId?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  username?: Maybe<Scalars['String']>;
};

export type PaymentInput = {
  paidAt?: InputMaybe<Scalars['AWSDateTime']>;
  paidCurrency: CurrencyType;
  paidTalentId: Scalars['String'];
  payerCompanyId: Scalars['String'];
  paymentAmount: Scalars['Float'];
};

export type Payroll = {
  __typename?: 'Payroll';
  companyId: Scalars['ID'];
  contractType: ContractType;
  currency: CurrencyType;
  id: Scalars['ID'];
  isPaidIn: Scalars['Boolean'];
  paymentAmount?: Maybe<Scalars['Float']>;
  talentId: Scalars['ID'];
  talentUsername: Scalars['String'];
  wage: Scalars['Float'];
  workHours: Scalars['String'];
};

export type PayrollDeleteResponse = {
  __typename?: 'PayrollDeleteResponse';
  ids: Array<Maybe<Scalars['ID']>>;
};

export type PayrollInput = {
  companyId: Scalars['ID'];
  contractType: ContractType;
  currency: CurrencyType;
  payrollOrderId: Scalars['String'];
  talentId: Scalars['ID'];
  wage: Scalars['Float'];
  workHours: Scalars['String'];
};

export type PayrollList = {
  __typename?: 'PayrollList';
  items?: Maybe<Array<Maybe<Payroll>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PayrollOrder = {
  __typename?: 'PayrollOrder';
  address?: Maybe<Scalars['String']>;
  companyId: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  currency?: Maybe<CurrencyType>;
  dueDateAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  status: PayrollOrderStatus;
  totalAmount?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type PayrollOrderCreateInput = {
  address?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /**   wallet address used for payment */
  status?: InputMaybe<PayrollOrderStatus>;
  totalAmount?: InputMaybe<Scalars['Float']>;
};

export type PayrollOrderFilterInput = {
  createdAt?: InputMaybe<CreatedAtFilterInput>;
  status?: InputMaybe<PayrollOrderStatus>;
};

export type PayrollOrderList = {
  __typename?: 'PayrollOrderList';
  items?: Maybe<Array<Maybe<PayrollOrder>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum PayrollOrderStatus {
  Confirmed = 'CONFIRMED',
  Created = 'CREATED',
  PaidIn = 'PAID_IN',
  PaidOut = 'PAID_OUT',
  Pending = 'PENDING'
}

export type PayrollOrderUpdateInput = {
  address?: InputMaybe<Scalars['String']>;
  /**   wallet address used for payment */
  currency?: InputMaybe<CurrencyType>;
  id: Scalars['ID'];
  status?: InputMaybe<PayrollOrderStatus>;
  totalAmount?: InputMaybe<Scalars['Float']>;
};

export type PayrollUpdateInput = {
  contractType?: InputMaybe<ContractType>;
  currency?: InputMaybe<CurrencyType>;
  id: Scalars['ID'];
  isPaidIn?: InputMaybe<Scalars['Boolean']>;
  paymentAmount?: InputMaybe<Scalars['Float']>;
  wage?: InputMaybe<Scalars['Float']>;
  workHours?: InputMaybe<Scalars['String']>;
};

export type PayrollsCreateInput = {
  payrolls: Array<PayrollInput>;
};

export type PayrollsDeleteInput = {
  payrollIds: Array<Scalars['ID']>;
};

export type Query = {
  __typename?: 'Query';
  companyGet?: Maybe<Company>;
  companyGetByEmailDomain?: Maybe<Company>;
  companyInviteGet?: Maybe<CompanyRequest>;
  companyInviteGetByEmail?: Maybe<CompanyInvite>;
  companyPaymentList?: Maybe<Array<Maybe<Payment>>>;
  companyRequestGet?: Maybe<CompanyRequest>;
  companyRequestGetByTalentAddress?: Maybe<CompanyRequest>;
  companyRequestListByCompany?: Maybe<CompanyRequestList>;
  companyRequestListByTalentAddress?: Maybe<CompanyRequestList>;
  companyUserGet?: Maybe<CompanyUser>;
  companyUserInviteGet?: Maybe<CompanyUserInvite>;
  companyUserInviteGetByEmail?: Maybe<CompanyUserInvite>;
  companyUserInviteListByCompany?: Maybe<CompanyUserInviteList>;
  companyUserListByCompany?: Maybe<CompanyUserList>;
  companyUserStatusGet?: Maybe<CompanyUserStatus>;
  documentKycGet?: Maybe<KycDocument>;
  documentList?: Maybe<DocumentList>;
  inviteCodeList?: Maybe<InviteCodeList>;
  merkleProofGet?: Maybe<MerkleProof>;
  payrollListByPayrollOrder?: Maybe<PayrollList>;
  payrollOrderGetByCompany?: Maybe<PayrollOrder>;
  payrollOrderListByCompany?: Maybe<PayrollOrderList>;
  talentEmailCheck?: Maybe<TalentEmailCheckResult>;
  talentGet?: Maybe<Talent>;
  talentGetByEmail?: Maybe<Talent>;
  talentPaymentList?: Maybe<Array<Maybe<Payment>>>;
  talentProfileGet?: Maybe<TalentProfile>;
  talentProfileGetBySlug?: Maybe<TalentProfile>;
  talentResumePseudonymize: TalentResumePseudonymizedResult;
};


export type QueryCompanyGetArgs = {
  id: Scalars['ID'];
};


export type QueryCompanyGetByEmailDomainArgs = {
  emailDomain: Scalars['String'];
};


export type QueryCompanyInviteGetArgs = {
  id: Scalars['ID'];
};


export type QueryCompanyInviteGetByEmailArgs = {
  email: Scalars['String'];
};


export type QueryCompanyPaymentListArgs = {
  id: Scalars['ID'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};


export type QueryCompanyRequestGetArgs = {
  id: Scalars['ID'];
};


export type QueryCompanyRequestGetByTalentAddressArgs = {
  address: Scalars['String'];
};


export type QueryCompanyRequestListByCompanyArgs = {
  companyId: Scalars['String'];
  filter?: InputMaybe<CompanyRequestFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  statusFilter?: InputMaybe<ContractStatus>;
};


export type QueryCompanyRequestListByTalentAddressArgs = {
  address: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryCompanyUserGetArgs = {
  id: Scalars['ID'];
};


export type QueryCompanyUserInviteGetArgs = {
  id: Scalars['ID'];
};


export type QueryCompanyUserInviteGetByEmailArgs = {
  email: Scalars['String'];
};


export type QueryCompanyUserInviteListByCompanyArgs = {
  companyId: Scalars['String'];
};


export type QueryCompanyUserListByCompanyArgs = {
  companyId: Scalars['String'];
};


export type QueryCompanyUserStatusGetArgs = {
  email: Scalars['String'];
  id: Scalars['ID'];
};


export type QueryDocumentKycGetArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentListArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  talentId: Scalars['String'];
};


export type QueryInviteCodeListArgs = {
  filter?: InputMaybe<InviteCodeFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  talentId: Scalars['String'];
};


export type QueryMerkleProofGetArgs = {
  countryCode: Scalars['String'];
};


export type QueryPayrollListByPayrollOrderArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  payrollOrderId: Scalars['String'];
};


export type QueryPayrollOrderGetByCompanyArgs = {
  companyId: Scalars['ID'];
  filter?: InputMaybe<PayrollOrderFilterInput>;
};


export type QueryPayrollOrderListByCompanyArgs = {
  companyId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryTalentEmailCheckArgs = {
  email: Scalars['String'];
};


export type QueryTalentGetArgs = {
  id: Scalars['ID'];
};


export type QueryTalentGetByEmailArgs = {
  email: Scalars['String'];
};


export type QueryTalentPaymentListArgs = {
  id: Scalars['ID'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};


export type QueryTalentProfileGetArgs = {
  id: Scalars['String'];
};


export type QueryTalentProfileGetBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryTalentResumePseudonymizeArgs = {
  input: TalentResumePseudonymizeInput;
};

export type ResetTalentOnboardingInput = {
  companyRequestId?: InputMaybe<Scalars['String']>;
  onboardingVersion: Scalars['Int'];
  talentId: Scalars['ID'];
};

export type ResetTalentOnboardingResult = {
  __typename?: 'ResetTalentOnboardingResult';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export enum Role {
  Admin = 'ADMIN',
  Viewer = 'VIEWER'
}

export type S3Object = {
  __typename?: 'S3Object';
  bucket: Scalars['String'];
  key: Scalars['String'];
  region: Scalars['String'];
};

export type S3ObjectInput = {
  bucket: Scalars['String'];
  key: Scalars['String'];
  region: Scalars['String'];
};

export type Talent = IUser & {
  __typename?: 'Talent';
  address?: Maybe<Scalars['String']>;
  cognitoIdentityId: Scalars['ID'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isInvited?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  kycSessionExpiresAt?: Maybe<Scalars['AWSDateTime']>;
  kycSessionId?: Maybe<Scalars['ID']>;
  nftTx?: Maybe<Scalars['String']>;
  nftUrl?: Maybe<Scalars['String']>;
  onboardingStatus?: Maybe<OnboardingStatus>;
  onboardingVersion: Scalars['Int'];
  openSeaUrl?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  zkVerifiedTx?: Maybe<Scalars['String']>;
};

export type TalentCreateInput = {
  cognitoIdentityId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isInvited: Scalars['Boolean'];
  /**   Optional */
  onboardingStatus: OnboardingStatus;
  onboardingVersion: Scalars['Int'];
};

export type TalentEmailCheckResult = {
  __typename?: 'TalentEmailCheckResult';
  exists: Scalars['Boolean'];
};

export type TalentInput = {
  id: Scalars['ID'];
  username?: InputMaybe<Scalars['String']>;
};

export type TalentProfile = {
  __typename?: 'TalentProfile';
  address?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  currentPosition?: Maybe<WorkHistory>;
  educations: Array<Education>;
  email?: Maybe<Scalars['String']>;
  hourlyRate?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  isPublic?: Maybe<Scalars['Boolean']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  jobRole?: Maybe<JobRole>;
  lookingFor?: Maybe<Scalars['String']>;
  openToFulltime?: Maybe<Scalars['Boolean']>;
  profilePhoto?: Maybe<Scalars['String']>;
  skills: Array<Scalars['String']>;
  slug: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  username?: Maybe<Scalars['String']>;
  weeklyAvailability?: Maybe<Scalars['Int']>;
  workHistories: Array<WorkHistory>;
};

export type TalentProfileCreateInput = {
  currentPosition?: InputMaybe<WorkHistoryInput>;
  educations?: InputMaybe<Array<EducationInput>>;
  hourlyRate?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  isPublic?: InputMaybe<Scalars['Boolean']>;
  jobRole?: InputMaybe<JobRole>;
  openToFulltime?: InputMaybe<Scalars['Boolean']>;
  skills?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  weeklyAvailability?: InputMaybe<Scalars['Int']>;
  workHistories?: InputMaybe<Array<WorkHistoryInput>>;
};

export type TalentProfileUpdateInput = {
  currentPosition?: InputMaybe<WorkHistoryInput>;
  educations?: InputMaybe<Array<EducationInput>>;
  field?: InputMaybe<Scalars['String']>;
  hourlyRate?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  industry?: InputMaybe<Scalars['String']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  jobRole?: InputMaybe<JobRole>;
  lookingFor?: InputMaybe<Scalars['String']>;
  openToFulltime?: InputMaybe<Scalars['Boolean']>;
  profilePhoto?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  slug?: InputMaybe<Scalars['String']>;
  weeklyAvailability?: InputMaybe<Scalars['Int']>;
  workHistories?: InputMaybe<Array<WorkHistoryInput>>;
};

export type TalentResume = ITalentResume & {
  __typename?: 'TalentResume';
  field?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  workHistory: Scalars['String'];
};

export type TalentResumePseudonymizeInput = {
  workHistory?: InputMaybe<Scalars['String']>;
};

export type TalentResumePseudonymizedResult = ITalentResume & {
  __typename?: 'TalentResumePseudonymizedResult';
  field?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Maybe<Scalars['String']>>>;
  success: Scalars['Boolean'];
  workSummaries?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TalentUpdateInput = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isInvited?: InputMaybe<Scalars['Boolean']>;
  isVerified?: InputMaybe<Scalars['Boolean']>;
  kycSessionExpiresAt?: InputMaybe<Scalars['AWSDateTime']>;
  kycSessionId?: InputMaybe<Scalars['ID']>;
  nftTx?: InputMaybe<Scalars['String']>;
  nftUrl?: InputMaybe<Scalars['String']>;
  onboardingStatus?: InputMaybe<OnboardingStatus>;
  openSeaUrl?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  zkVerifiedTx?: InputMaybe<Scalars['String']>;
};

export type UpdateDocumentInput = {
  docStatus?: InputMaybe<DocumentStatus>;
  id: Scalars['ID'];
};

export type VerificationCode = {
  __typename?: 'VerificationCode';
  code: Scalars['String'];
  email: Scalars['String'];
  expireAt: Scalars['String'];
  id: Scalars['ID'];
  isUsed?: Maybe<Scalars['Boolean']>;
  talentId: Scalars['String'];
};

export type VerifyCodeInput = {
  code: Scalars['String'];
  email: Scalars['String'];
};

export type VerifyCodeResult = {
  __typename?: 'VerifyCodeResult';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type VerifyInviteCodeInput = {
  code: Scalars['String'];
  talentId: Scalars['ID'];
};

export type VerifyInviteCodeResult = {
  __typename?: 'VerifyInviteCodeResult';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type VerifyProofInput = {
  from: Scalars['String'];
  input: Array<Scalars['String']>;
  nonce: Scalars['Int'];
  proof: ZkProofInput;
  signature: Scalars['String'];
};

export type VerifyProofMutationResult = {
  __typename?: 'VerifyProofMutationResult';
  triggered?: Maybe<Scalars['Boolean']>;
};

export type WorkHistory = {
  __typename?: 'WorkHistory';
  detail?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  field?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type WorkHistoryInput = {
  detail?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  field?: InputMaybe<Scalars['String']>;
  industry?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

export type ZkProofInput = {
  proof_0: Scalars['String'];
  proof_1: Scalars['String'];
  proof_2: Scalars['String'];
  proof_3: Scalars['String'];
  proof_4: Scalars['String'];
  proof_5: Scalars['String'];
  proof_6: Scalars['String'];
  proof_7: Scalars['String'];
};

export type CompanyCreateMutationVariables = Exact<{
  input: CompanyCreateInput;
}>;


export type CompanyCreateMutation = { __typename?: 'Mutation', companyCreate?: { __typename?: 'Company', id: string, email: string, address: string } | null };

export type CompanyRequestCreateMutationVariables = Exact<{
  input: CompanyRequestCreateInput;
}>;


export type CompanyRequestCreateMutation = { __typename?: 'Mutation', companyRequestCreate?: { __typename?: 'CompanyRequest', email: string, talentId?: string | null } | null };

export type CompanyRequestUpdateMutationVariables = Exact<{
  input: CompanyRequestUpdateInput;
}>;


export type CompanyRequestUpdateMutation = { __typename?: 'Mutation', companyRequestUpdate?: { __typename?: 'CompanyRequest', email: string, talentId?: string | null } | null };

export type CompanyUserCreateMutationVariables = Exact<{
  input: CompanyUserCreateInput;
}>;


export type CompanyUserCreateMutation = { __typename?: 'Mutation', companyUserCreate?: { __typename?: 'CompanyUser', email?: string | null, companyId?: string | null, role?: Role | null } | null };

export type CompanyUserInviteCreateMutationVariables = Exact<{
  companyId: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  inviterName: Scalars['String'];
  role: Role;
}>;


export type CompanyUserInviteCreateMutation = { __typename?: 'Mutation', companyUserInviteCreate?: { __typename?: 'CompanyUserInvite', companyId: string, email: string, firstName?: string | null, id: string, inviterName: string, role: Role } | null };

export type CompanyUserUpdateMutationVariables = Exact<{
  input: CompanyUserUpdateInput;
}>;


export type CompanyUserUpdateMutation = { __typename?: 'Mutation', companyUserUpdate?: { __typename?: 'CompanyUser', email?: string | null } | null };

export type PayrollOrderUpdateMutationVariables = Exact<{
  input: PayrollOrderUpdateInput;
}>;


export type PayrollOrderUpdateMutation = { __typename?: 'Mutation', payrollOrderUpdate?: { __typename?: 'PayrollOrder', id: string, companyId: string, totalAmount?: number | null, currency?: CurrencyType | null, address?: string | null, status: PayrollOrderStatus } | null };

export type PayrollRejectMutationVariables = Exact<{
  payrollOrderId: Scalars['ID'];
  payrollIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type PayrollRejectMutation = { __typename?: 'Mutation', payrollOrderUpdate?: { __typename?: 'PayrollOrder', id: string, status: PayrollOrderStatus } | null, payrollsDelete?: { __typename?: 'PayrollDeleteResponse', ids: Array<string | null> } | null };

export type PayrollRunMutationVariables = Exact<{
  payrollOrderUpdateInput: PayrollOrderUpdateInput;
  payrollsCreateInput: PayrollsCreateInput;
}>;


export type PayrollRunMutation = { __typename?: 'Mutation', payrollOrderUpdate?: { __typename?: 'PayrollOrder', id: string, companyId: string, totalAmount?: number | null, currency?: CurrencyType | null, address?: string | null, status: PayrollOrderStatus } | null, payrollsCreate?: Array<{ __typename?: 'Payroll', id: string, companyId: string, talentId: string, contractType: ContractType, wage: number, currency: CurrencyType, workHours: string } | null> | null };

export type PayrollsCreateMutationVariables = Exact<{
  input: PayrollsCreateInput;
}>;


export type PayrollsCreateMutation = { __typename?: 'Mutation', payrollsCreate?: Array<{ __typename?: 'Payroll', id: string, companyId: string, talentId: string, contractType: ContractType, wage: number, currency: CurrencyType, workHours: string } | null> | null };

export type CompanyGetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanyGetQuery = { __typename?: 'Query', companyGet?: { __typename?: 'Company', name: string, email: string, address: string, address2?: string | null, city: string, state: string, zipCode: string, country: string } | null };

export type CompanyPaymentListQueryVariables = Exact<{
  id: Scalars['ID'];
  take: Scalars['Int'];
  skip: Scalars['Int'];
}>;


export type CompanyPaymentListQuery = { __typename?: 'Query', companyPaymentList?: Array<{ __typename?: 'Payment', id?: string | null, createdAt?: any | null, paidAt?: any | null, paidCurrency?: CurrencyType | null, paidTalentId?: string | null, paymentAmount?: number | null, username?: string | null } | null> | null };

export type CompanyRequestListByCompanyQueryVariables = Exact<{
  companyId: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<CompanyRequestFilterInput>;
}>;


export type CompanyRequestListByCompanyQuery = { __typename?: 'Query', companyRequestListByCompany?: { __typename?: 'CompanyRequestList', nextToken?: string | null, items?: Array<{ __typename?: 'CompanyRequestListResponse', id: string, email: string, contractStatus?: ContractStatus | null, currency?: CurrencyType | null, wage?: number | null, createdAt?: any | null, updatedAt?: any | null, talentId?: string | null, contractType?: ContractType | null, talentName?: string | null, startDate?: any | null } | null> | null } | null };

export type CompanyUserGetQueryVariables = Exact<{
  identityId: Scalars['ID'];
}>;


export type CompanyUserGetQuery = { __typename?: 'Query', companyUserGet?: { __typename?: 'CompanyUser', id: string, email?: string | null, companyId?: string | null, role?: Role | null, firstName?: string | null, middleName?: string | null, lastName?: string | null, title?: string | null } | null };

export type CompanyUserInviteGetQueryVariables = Exact<{
  inviteId: Scalars['ID'];
}>;


export type CompanyUserInviteGetQuery = { __typename?: 'Query', companyUserInviteGet?: { __typename?: 'CompanyUserInvite', companyId: string, email: string, firstName?: string | null, id: string, inviterName: string, role: Role } | null };

export type CompanyUserInviteGetByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type CompanyUserInviteGetByEmailQuery = { __typename?: 'Query', companyUserInviteGetByEmail?: { __typename?: 'CompanyUserInvite', companyId: string, email: string, firstName?: string | null, id: string, inviterName: string, role: Role } | null };

export type CompanyUserInviteListByCompanyQueryVariables = Exact<{
  companyId: Scalars['String'];
}>;


export type CompanyUserInviteListByCompanyQuery = { __typename?: 'Query', companyUserInviteListByCompany?: { __typename?: 'CompanyUserInviteList', nextToken?: string | null, items?: Array<{ __typename?: 'CompanyUserInvite', companyId: string, email: string, firstName?: string | null, id: string, inviterName: string, role: Role } | null> | null } | null };

export type CompanyUserListByCompanyQueryVariables = Exact<{
  companyId: Scalars['String'];
}>;


export type CompanyUserListByCompanyQuery = { __typename?: 'Query', companyUserListByCompany?: { __typename?: 'CompanyUserList', nextToken?: string | null, items?: Array<{ __typename?: 'CompanyUser', companyId?: string | null, createdAt?: any | null, email?: string | null, firstName?: string | null, id: string, lastName?: string | null, middleName?: string | null, role?: Role | null, title?: string | null } | null> | null } | null };

export type CompanyUserStatusGetQueryVariables = Exact<{
  id: Scalars['ID'];
  email: Scalars['String'];
}>;


export type CompanyUserStatusGetQuery = { __typename?: 'Query', companyUserStatusGet?: { __typename?: 'CompanyUserStatus', status?: CompanyUserStatusType | null, companyUser?: { __typename?: 'CompanyUser', id: string, email?: string | null, companyId?: string | null, createdAt?: any | null } | null } | null };

export type InitialUserGetQueryVariables = Exact<{
  identityId: Scalars['ID'];
  inviteEmail: Scalars['String'];
  emailDomain: Scalars['String'];
}>;


export type InitialUserGetQuery = { __typename?: 'Query', companyUserGet?: { __typename?: 'CompanyUser', id: string, email?: string | null, firstName?: string | null, middleName?: string | null, lastName?: string | null, title?: string | null, companyId?: string | null, createdAt?: any | null } | null, companyUserInviteGetByEmail?: { __typename?: 'CompanyUserInvite', companyId: string, email: string, firstName?: string | null, id: string, inviterName: string, role: Role } | null, companyGetByEmailDomain?: { __typename?: 'Company', email: string, id: string, name: string } | null };

export type PayrollListByPayrollOrderQueryVariables = Exact<{
  payrollOrderId: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type PayrollListByPayrollOrderQuery = { __typename?: 'Query', payrollListByPayrollOrder?: { __typename?: 'PayrollList', nextToken?: string | null, items?: Array<{ __typename?: 'Payroll', companyId: string, contractType: ContractType, currency: CurrencyType, id: string, talentId: string, wage: number, workHours: string, isPaidIn: boolean, talentUsername: string, paymentAmount?: number | null } | null> | null } | null };

export type PayrollOrderGetByCompanyQueryVariables = Exact<{
  companyId: Scalars['ID'];
  filter?: InputMaybe<PayrollOrderFilterInput>;
}>;


export type PayrollOrderGetByCompanyQuery = { __typename?: 'Query', payrollOrderGetByCompany?: { __typename?: 'PayrollOrder', address?: string | null, companyId: string, createdAt: any, currency?: CurrencyType | null, dueDateAt: any, id: string, status: PayrollOrderStatus, totalAmount?: number | null, updatedAt?: any | null } | null };

export type PayrollOrderListByCompanyQueryVariables = Exact<{
  companyId: Scalars['ID'];
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type PayrollOrderListByCompanyQuery = { __typename?: 'Query', payrollOrderListByCompany?: { __typename?: 'PayrollOrderList', nextToken?: string | null, items?: Array<{ __typename?: 'PayrollOrder', address?: string | null, companyId: string, createdAt: any, currency?: CurrencyType | null, dueDateAt: any, id: string, status: PayrollOrderStatus, totalAmount?: number | null, updatedAt?: any | null } | null> | null } | null };


export const CompanyCreateDocument = `
    mutation companyCreate($input: CompanyCreateInput!) {
  companyCreate(input: $input) {
    id
    email
    address
  }
}
    `;
export const useCompanyCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CompanyCreateMutation, TError, CompanyCreateMutationVariables, TContext>) =>
    useMutation<CompanyCreateMutation, TError, CompanyCreateMutationVariables, TContext>(
      ['companyCreate'],
      (variables?: CompanyCreateMutationVariables) => gqlOp<CompanyCreateMutation, CompanyCreateMutationVariables>(CompanyCreateDocument, variables)(),
      options
    );
export const CompanyRequestCreateDocument = `
    mutation companyRequestCreate($input: CompanyRequestCreateInput!) {
  companyRequestCreate(input: $input) {
    email
    talentId
  }
}
    `;
export const useCompanyRequestCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CompanyRequestCreateMutation, TError, CompanyRequestCreateMutationVariables, TContext>) =>
    useMutation<CompanyRequestCreateMutation, TError, CompanyRequestCreateMutationVariables, TContext>(
      ['companyRequestCreate'],
      (variables?: CompanyRequestCreateMutationVariables) => gqlOp<CompanyRequestCreateMutation, CompanyRequestCreateMutationVariables>(CompanyRequestCreateDocument, variables)(),
      options
    );
export const CompanyRequestUpdateDocument = `
    mutation companyRequestUpdate($input: CompanyRequestUpdateInput!) {
  companyRequestUpdate(input: $input) {
    email
    talentId
  }
}
    `;
export const useCompanyRequestUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CompanyRequestUpdateMutation, TError, CompanyRequestUpdateMutationVariables, TContext>) =>
    useMutation<CompanyRequestUpdateMutation, TError, CompanyRequestUpdateMutationVariables, TContext>(
      ['companyRequestUpdate'],
      (variables?: CompanyRequestUpdateMutationVariables) => gqlOp<CompanyRequestUpdateMutation, CompanyRequestUpdateMutationVariables>(CompanyRequestUpdateDocument, variables)(),
      options
    );
export const CompanyUserCreateDocument = `
    mutation companyUserCreate($input: CompanyUserCreateInput!) {
  companyUserCreate(input: $input) {
    email
    companyId
    role
  }
}
    `;
export const useCompanyUserCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CompanyUserCreateMutation, TError, CompanyUserCreateMutationVariables, TContext>) =>
    useMutation<CompanyUserCreateMutation, TError, CompanyUserCreateMutationVariables, TContext>(
      ['companyUserCreate'],
      (variables?: CompanyUserCreateMutationVariables) => gqlOp<CompanyUserCreateMutation, CompanyUserCreateMutationVariables>(CompanyUserCreateDocument, variables)(),
      options
    );
export const CompanyUserInviteCreateDocument = `
    mutation companyUserInviteCreate($companyId: ID!, $email: String!, $firstName: String!, $inviterName: String!, $role: Role!) {
  companyUserInviteCreate(
    input: {companyId: $companyId, email: $email, firstName: $firstName, inviterName: $inviterName, role: $role}
  ) {
    companyId
    email
    firstName
    id
    inviterName
    role
  }
}
    `;
export const useCompanyUserInviteCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CompanyUserInviteCreateMutation, TError, CompanyUserInviteCreateMutationVariables, TContext>) =>
    useMutation<CompanyUserInviteCreateMutation, TError, CompanyUserInviteCreateMutationVariables, TContext>(
      ['companyUserInviteCreate'],
      (variables?: CompanyUserInviteCreateMutationVariables) => gqlOp<CompanyUserInviteCreateMutation, CompanyUserInviteCreateMutationVariables>(CompanyUserInviteCreateDocument, variables)(),
      options
    );
export const CompanyUserUpdateDocument = `
    mutation companyUserUpdate($input: CompanyUserUpdateInput!) {
  companyUserUpdate(input: $input) {
    email
  }
}
    `;
export const useCompanyUserUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CompanyUserUpdateMutation, TError, CompanyUserUpdateMutationVariables, TContext>) =>
    useMutation<CompanyUserUpdateMutation, TError, CompanyUserUpdateMutationVariables, TContext>(
      ['companyUserUpdate'],
      (variables?: CompanyUserUpdateMutationVariables) => gqlOp<CompanyUserUpdateMutation, CompanyUserUpdateMutationVariables>(CompanyUserUpdateDocument, variables)(),
      options
    );
export const PayrollOrderUpdateDocument = `
    mutation payrollOrderUpdate($input: PayrollOrderUpdateInput!) {
  payrollOrderUpdate(input: $input) {
    id
    companyId
    totalAmount
    currency
    address
    status
  }
}
    `;
export const usePayrollOrderUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PayrollOrderUpdateMutation, TError, PayrollOrderUpdateMutationVariables, TContext>) =>
    useMutation<PayrollOrderUpdateMutation, TError, PayrollOrderUpdateMutationVariables, TContext>(
      ['payrollOrderUpdate'],
      (variables?: PayrollOrderUpdateMutationVariables) => gqlOp<PayrollOrderUpdateMutation, PayrollOrderUpdateMutationVariables>(PayrollOrderUpdateDocument, variables)(),
      options
    );
export const PayrollRejectDocument = `
    mutation payrollReject($payrollOrderId: ID!, $payrollIds: [ID!]!) {
  payrollOrderUpdate(input: {id: $payrollOrderId, status: CREATED}) {
    id
    status
  }
  payrollsDelete(input: {payrollIds: $payrollIds}) {
    ids
  }
}
    `;
export const usePayrollRejectMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PayrollRejectMutation, TError, PayrollRejectMutationVariables, TContext>) =>
    useMutation<PayrollRejectMutation, TError, PayrollRejectMutationVariables, TContext>(
      ['payrollReject'],
      (variables?: PayrollRejectMutationVariables) => gqlOp<PayrollRejectMutation, PayrollRejectMutationVariables>(PayrollRejectDocument, variables)(),
      options
    );
export const PayrollRunDocument = `
    mutation payrollRun($payrollOrderUpdateInput: PayrollOrderUpdateInput!, $payrollsCreateInput: PayrollsCreateInput!) {
  payrollOrderUpdate(input: $payrollOrderUpdateInput) {
    id
    companyId
    totalAmount
    currency
    address
    status
  }
  payrollsCreate(input: $payrollsCreateInput) {
    id
    companyId
    talentId
    contractType
    wage
    currency
    workHours
  }
}
    `;
export const usePayrollRunMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PayrollRunMutation, TError, PayrollRunMutationVariables, TContext>) =>
    useMutation<PayrollRunMutation, TError, PayrollRunMutationVariables, TContext>(
      ['payrollRun'],
      (variables?: PayrollRunMutationVariables) => gqlOp<PayrollRunMutation, PayrollRunMutationVariables>(PayrollRunDocument, variables)(),
      options
    );
export const PayrollsCreateDocument = `
    mutation payrollsCreate($input: PayrollsCreateInput!) {
  payrollsCreate(input: $input) {
    id
    companyId
    talentId
    contractType
    wage
    currency
    workHours
  }
}
    `;
export const usePayrollsCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PayrollsCreateMutation, TError, PayrollsCreateMutationVariables, TContext>) =>
    useMutation<PayrollsCreateMutation, TError, PayrollsCreateMutationVariables, TContext>(
      ['payrollsCreate'],
      (variables?: PayrollsCreateMutationVariables) => gqlOp<PayrollsCreateMutation, PayrollsCreateMutationVariables>(PayrollsCreateDocument, variables)(),
      options
    );
export const CompanyGetDocument = `
    query companyGet($id: ID!) {
  companyGet(id: $id) {
    name
    email
    address
    address2
    city
    state
    zipCode
    country
  }
}
    `;
export const useCompanyGetQuery = <
      TData = CompanyGetQuery,
      TError = unknown
    >(
      variables: CompanyGetQueryVariables,
      options?: UseQueryOptions<CompanyGetQuery, TError, TData>
    ) =>
    useQuery<CompanyGetQuery, TError, TData>(
      ['companyGet', variables],
      gqlOp<CompanyGetQuery, CompanyGetQueryVariables>(CompanyGetDocument, variables),
      options
    );
export const useInfiniteCompanyGetQuery = <
      TData = CompanyGetQuery,
      TError = unknown
    >(
      variables: CompanyGetQueryVariables,
      options?: UseInfiniteQueryOptions<CompanyGetQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CompanyGetQuery, TError, TData>(
      ['companyGet.infinite', variables],
      (metaData) => gqlOp<CompanyGetQuery, CompanyGetQueryVariables>(CompanyGetDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const CompanyPaymentListDocument = `
    query companyPaymentList($id: ID!, $take: Int!, $skip: Int!) {
  companyPaymentList(id: $id, take: $take, skip: $skip) {
    id
    createdAt
    paidAt
    paidCurrency
    paidTalentId
    paymentAmount
    username
  }
}
    `;
export const useCompanyPaymentListQuery = <
      TData = CompanyPaymentListQuery,
      TError = unknown
    >(
      variables: CompanyPaymentListQueryVariables,
      options?: UseQueryOptions<CompanyPaymentListQuery, TError, TData>
    ) =>
    useQuery<CompanyPaymentListQuery, TError, TData>(
      ['companyPaymentList', variables],
      gqlOp<CompanyPaymentListQuery, CompanyPaymentListQueryVariables>(CompanyPaymentListDocument, variables),
      options
    );
export const useInfiniteCompanyPaymentListQuery = <
      TData = CompanyPaymentListQuery,
      TError = unknown
    >(
      variables: CompanyPaymentListQueryVariables,
      options?: UseInfiniteQueryOptions<CompanyPaymentListQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CompanyPaymentListQuery, TError, TData>(
      ['companyPaymentList.infinite', variables],
      (metaData) => gqlOp<CompanyPaymentListQuery, CompanyPaymentListQueryVariables>(CompanyPaymentListDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const CompanyRequestListByCompanyDocument = `
    query companyRequestListByCompany($companyId: String!, $nextToken: String, $limit: Int, $filter: CompanyRequestFilterInput) {
  companyRequestListByCompany(
    companyId: $companyId
    nextToken: $nextToken
    limit: $limit
    filter: $filter
  ) {
    items {
      id
      email
      contractStatus
      currency
      wage
      createdAt
      updatedAt
      talentId
      contractType
      talentName
      startDate
    }
    nextToken
  }
}
    `;
export const useCompanyRequestListByCompanyQuery = <
      TData = CompanyRequestListByCompanyQuery,
      TError = unknown
    >(
      variables: CompanyRequestListByCompanyQueryVariables,
      options?: UseQueryOptions<CompanyRequestListByCompanyQuery, TError, TData>
    ) =>
    useQuery<CompanyRequestListByCompanyQuery, TError, TData>(
      ['companyRequestListByCompany', variables],
      gqlOp<CompanyRequestListByCompanyQuery, CompanyRequestListByCompanyQueryVariables>(CompanyRequestListByCompanyDocument, variables),
      options
    );
export const useInfiniteCompanyRequestListByCompanyQuery = <
      TData = CompanyRequestListByCompanyQuery,
      TError = unknown
    >(
      variables: CompanyRequestListByCompanyQueryVariables,
      options?: UseInfiniteQueryOptions<CompanyRequestListByCompanyQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CompanyRequestListByCompanyQuery, TError, TData>(
      ['companyRequestListByCompany.infinite', variables],
      (metaData) => gqlOp<CompanyRequestListByCompanyQuery, CompanyRequestListByCompanyQueryVariables>(CompanyRequestListByCompanyDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const CompanyUserGetDocument = `
    query companyUserGet($identityId: ID!) {
  companyUserGet(id: $identityId) {
    id
    email
    companyId
    role
    firstName
    middleName
    lastName
    title
  }
}
    `;
export const useCompanyUserGetQuery = <
      TData = CompanyUserGetQuery,
      TError = unknown
    >(
      variables: CompanyUserGetQueryVariables,
      options?: UseQueryOptions<CompanyUserGetQuery, TError, TData>
    ) =>
    useQuery<CompanyUserGetQuery, TError, TData>(
      ['companyUserGet', variables],
      gqlOp<CompanyUserGetQuery, CompanyUserGetQueryVariables>(CompanyUserGetDocument, variables),
      options
    );
export const useInfiniteCompanyUserGetQuery = <
      TData = CompanyUserGetQuery,
      TError = unknown
    >(
      variables: CompanyUserGetQueryVariables,
      options?: UseInfiniteQueryOptions<CompanyUserGetQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CompanyUserGetQuery, TError, TData>(
      ['companyUserGet.infinite', variables],
      (metaData) => gqlOp<CompanyUserGetQuery, CompanyUserGetQueryVariables>(CompanyUserGetDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const CompanyUserInviteGetDocument = `
    query companyUserInviteGet($inviteId: ID!) {
  companyUserInviteGet(id: $inviteId) {
    companyId
    email
    firstName
    id
    inviterName
    role
  }
}
    `;
export const useCompanyUserInviteGetQuery = <
      TData = CompanyUserInviteGetQuery,
      TError = unknown
    >(
      variables: CompanyUserInviteGetQueryVariables,
      options?: UseQueryOptions<CompanyUserInviteGetQuery, TError, TData>
    ) =>
    useQuery<CompanyUserInviteGetQuery, TError, TData>(
      ['companyUserInviteGet', variables],
      gqlOp<CompanyUserInviteGetQuery, CompanyUserInviteGetQueryVariables>(CompanyUserInviteGetDocument, variables),
      options
    );
export const useInfiniteCompanyUserInviteGetQuery = <
      TData = CompanyUserInviteGetQuery,
      TError = unknown
    >(
      variables: CompanyUserInviteGetQueryVariables,
      options?: UseInfiniteQueryOptions<CompanyUserInviteGetQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CompanyUserInviteGetQuery, TError, TData>(
      ['companyUserInviteGet.infinite', variables],
      (metaData) => gqlOp<CompanyUserInviteGetQuery, CompanyUserInviteGetQueryVariables>(CompanyUserInviteGetDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const CompanyUserInviteGetByEmailDocument = `
    query companyUserInviteGetByEmail($email: String!) {
  companyUserInviteGetByEmail(email: $email) {
    companyId
    email
    firstName
    id
    inviterName
    role
  }
}
    `;
export const useCompanyUserInviteGetByEmailQuery = <
      TData = CompanyUserInviteGetByEmailQuery,
      TError = unknown
    >(
      variables: CompanyUserInviteGetByEmailQueryVariables,
      options?: UseQueryOptions<CompanyUserInviteGetByEmailQuery, TError, TData>
    ) =>
    useQuery<CompanyUserInviteGetByEmailQuery, TError, TData>(
      ['companyUserInviteGetByEmail', variables],
      gqlOp<CompanyUserInviteGetByEmailQuery, CompanyUserInviteGetByEmailQueryVariables>(CompanyUserInviteGetByEmailDocument, variables),
      options
    );
export const useInfiniteCompanyUserInviteGetByEmailQuery = <
      TData = CompanyUserInviteGetByEmailQuery,
      TError = unknown
    >(
      variables: CompanyUserInviteGetByEmailQueryVariables,
      options?: UseInfiniteQueryOptions<CompanyUserInviteGetByEmailQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CompanyUserInviteGetByEmailQuery, TError, TData>(
      ['companyUserInviteGetByEmail.infinite', variables],
      (metaData) => gqlOp<CompanyUserInviteGetByEmailQuery, CompanyUserInviteGetByEmailQueryVariables>(CompanyUserInviteGetByEmailDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const CompanyUserInviteListByCompanyDocument = `
    query companyUserInviteListByCompany($companyId: String!) {
  companyUserInviteListByCompany(companyId: $companyId) {
    items {
      companyId
      email
      firstName
      id
      inviterName
      role
    }
    nextToken
  }
}
    `;
export const useCompanyUserInviteListByCompanyQuery = <
      TData = CompanyUserInviteListByCompanyQuery,
      TError = unknown
    >(
      variables: CompanyUserInviteListByCompanyQueryVariables,
      options?: UseQueryOptions<CompanyUserInviteListByCompanyQuery, TError, TData>
    ) =>
    useQuery<CompanyUserInviteListByCompanyQuery, TError, TData>(
      ['companyUserInviteListByCompany', variables],
      gqlOp<CompanyUserInviteListByCompanyQuery, CompanyUserInviteListByCompanyQueryVariables>(CompanyUserInviteListByCompanyDocument, variables),
      options
    );
export const useInfiniteCompanyUserInviteListByCompanyQuery = <
      TData = CompanyUserInviteListByCompanyQuery,
      TError = unknown
    >(
      variables: CompanyUserInviteListByCompanyQueryVariables,
      options?: UseInfiniteQueryOptions<CompanyUserInviteListByCompanyQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CompanyUserInviteListByCompanyQuery, TError, TData>(
      ['companyUserInviteListByCompany.infinite', variables],
      (metaData) => gqlOp<CompanyUserInviteListByCompanyQuery, CompanyUserInviteListByCompanyQueryVariables>(CompanyUserInviteListByCompanyDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const CompanyUserListByCompanyDocument = `
    query companyUserListByCompany($companyId: String!) {
  companyUserListByCompany(companyId: $companyId) {
    items {
      companyId
      createdAt
      email
      firstName
      id
      lastName
      middleName
      role
      title
    }
    nextToken
  }
}
    `;
export const useCompanyUserListByCompanyQuery = <
      TData = CompanyUserListByCompanyQuery,
      TError = unknown
    >(
      variables: CompanyUserListByCompanyQueryVariables,
      options?: UseQueryOptions<CompanyUserListByCompanyQuery, TError, TData>
    ) =>
    useQuery<CompanyUserListByCompanyQuery, TError, TData>(
      ['companyUserListByCompany', variables],
      gqlOp<CompanyUserListByCompanyQuery, CompanyUserListByCompanyQueryVariables>(CompanyUserListByCompanyDocument, variables),
      options
    );
export const useInfiniteCompanyUserListByCompanyQuery = <
      TData = CompanyUserListByCompanyQuery,
      TError = unknown
    >(
      variables: CompanyUserListByCompanyQueryVariables,
      options?: UseInfiniteQueryOptions<CompanyUserListByCompanyQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CompanyUserListByCompanyQuery, TError, TData>(
      ['companyUserListByCompany.infinite', variables],
      (metaData) => gqlOp<CompanyUserListByCompanyQuery, CompanyUserListByCompanyQueryVariables>(CompanyUserListByCompanyDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const CompanyUserStatusGetDocument = `
    query companyUserStatusGet($id: ID!, $email: String!) {
  companyUserStatusGet(id: $id, email: $email) {
    companyUser {
      id
      email
      companyId
      createdAt
    }
    status
  }
}
    `;
export const useCompanyUserStatusGetQuery = <
      TData = CompanyUserStatusGetQuery,
      TError = unknown
    >(
      variables: CompanyUserStatusGetQueryVariables,
      options?: UseQueryOptions<CompanyUserStatusGetQuery, TError, TData>
    ) =>
    useQuery<CompanyUserStatusGetQuery, TError, TData>(
      ['companyUserStatusGet', variables],
      gqlOp<CompanyUserStatusGetQuery, CompanyUserStatusGetQueryVariables>(CompanyUserStatusGetDocument, variables),
      options
    );
export const useInfiniteCompanyUserStatusGetQuery = <
      TData = CompanyUserStatusGetQuery,
      TError = unknown
    >(
      variables: CompanyUserStatusGetQueryVariables,
      options?: UseInfiniteQueryOptions<CompanyUserStatusGetQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<CompanyUserStatusGetQuery, TError, TData>(
      ['companyUserStatusGet.infinite', variables],
      (metaData) => gqlOp<CompanyUserStatusGetQuery, CompanyUserStatusGetQueryVariables>(CompanyUserStatusGetDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const InitialUserGetDocument = `
    query initialUserGet($identityId: ID!, $inviteEmail: String!, $emailDomain: String!) {
  companyUserGet(id: $identityId) {
    id
    email
    firstName
    middleName
    lastName
    title
    companyId
    createdAt
  }
  companyUserInviteGetByEmail(email: $inviteEmail) {
    companyId
    email
    firstName
    id
    inviterName
    role
  }
  companyGetByEmailDomain(emailDomain: $emailDomain) {
    email
    id
    name
  }
}
    `;
export const useInitialUserGetQuery = <
      TData = InitialUserGetQuery,
      TError = unknown
    >(
      variables: InitialUserGetQueryVariables,
      options?: UseQueryOptions<InitialUserGetQuery, TError, TData>
    ) =>
    useQuery<InitialUserGetQuery, TError, TData>(
      ['initialUserGet', variables],
      gqlOp<InitialUserGetQuery, InitialUserGetQueryVariables>(InitialUserGetDocument, variables),
      options
    );
export const useInfiniteInitialUserGetQuery = <
      TData = InitialUserGetQuery,
      TError = unknown
    >(
      variables: InitialUserGetQueryVariables,
      options?: UseInfiniteQueryOptions<InitialUserGetQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<InitialUserGetQuery, TError, TData>(
      ['initialUserGet.infinite', variables],
      (metaData) => gqlOp<InitialUserGetQuery, InitialUserGetQueryVariables>(InitialUserGetDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const PayrollListByPayrollOrderDocument = `
    query payrollListByPayrollOrder($payrollOrderId: String!, $nextToken: String) {
  payrollListByPayrollOrder(
    payrollOrderId: $payrollOrderId
    nextToken: $nextToken
  ) {
    items {
      companyId
      contractType
      currency
      id
      talentId
      wage
      workHours
      isPaidIn
      talentUsername
      paymentAmount
    }
    nextToken
  }
}
    `;
export const usePayrollListByPayrollOrderQuery = <
      TData = PayrollListByPayrollOrderQuery,
      TError = unknown
    >(
      variables: PayrollListByPayrollOrderQueryVariables,
      options?: UseQueryOptions<PayrollListByPayrollOrderQuery, TError, TData>
    ) =>
    useQuery<PayrollListByPayrollOrderQuery, TError, TData>(
      ['payrollListByPayrollOrder', variables],
      gqlOp<PayrollListByPayrollOrderQuery, PayrollListByPayrollOrderQueryVariables>(PayrollListByPayrollOrderDocument, variables),
      options
    );
export const useInfinitePayrollListByPayrollOrderQuery = <
      TData = PayrollListByPayrollOrderQuery,
      TError = unknown
    >(
      variables: PayrollListByPayrollOrderQueryVariables,
      options?: UseInfiniteQueryOptions<PayrollListByPayrollOrderQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<PayrollListByPayrollOrderQuery, TError, TData>(
      ['payrollListByPayrollOrder.infinite', variables],
      (metaData) => gqlOp<PayrollListByPayrollOrderQuery, PayrollListByPayrollOrderQueryVariables>(PayrollListByPayrollOrderDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const PayrollOrderGetByCompanyDocument = `
    query payrollOrderGetByCompany($companyId: ID!, $filter: PayrollOrderFilterInput) {
  payrollOrderGetByCompany(companyId: $companyId, filter: $filter) {
    address
    companyId
    createdAt
    currency
    dueDateAt
    id
    status
    totalAmount
    updatedAt
  }
}
    `;
export const usePayrollOrderGetByCompanyQuery = <
      TData = PayrollOrderGetByCompanyQuery,
      TError = unknown
    >(
      variables: PayrollOrderGetByCompanyQueryVariables,
      options?: UseQueryOptions<PayrollOrderGetByCompanyQuery, TError, TData>
    ) =>
    useQuery<PayrollOrderGetByCompanyQuery, TError, TData>(
      ['payrollOrderGetByCompany', variables],
      gqlOp<PayrollOrderGetByCompanyQuery, PayrollOrderGetByCompanyQueryVariables>(PayrollOrderGetByCompanyDocument, variables),
      options
    );
export const useInfinitePayrollOrderGetByCompanyQuery = <
      TData = PayrollOrderGetByCompanyQuery,
      TError = unknown
    >(
      variables: PayrollOrderGetByCompanyQueryVariables,
      options?: UseInfiniteQueryOptions<PayrollOrderGetByCompanyQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<PayrollOrderGetByCompanyQuery, TError, TData>(
      ['payrollOrderGetByCompany.infinite', variables],
      (metaData) => gqlOp<PayrollOrderGetByCompanyQuery, PayrollOrderGetByCompanyQueryVariables>(PayrollOrderGetByCompanyDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};

export const PayrollOrderListByCompanyDocument = `
    query payrollOrderListByCompany($companyId: ID!, $nextToken: String) {
  payrollOrderListByCompany(companyId: $companyId, nextToken: $nextToken) {
    nextToken
    items {
      address
      companyId
      createdAt
      currency
      dueDateAt
      id
      status
      totalAmount
      updatedAt
    }
  }
}
    `;
export const usePayrollOrderListByCompanyQuery = <
      TData = PayrollOrderListByCompanyQuery,
      TError = unknown
    >(
      variables: PayrollOrderListByCompanyQueryVariables,
      options?: UseQueryOptions<PayrollOrderListByCompanyQuery, TError, TData>
    ) =>
    useQuery<PayrollOrderListByCompanyQuery, TError, TData>(
      ['payrollOrderListByCompany', variables],
      gqlOp<PayrollOrderListByCompanyQuery, PayrollOrderListByCompanyQueryVariables>(PayrollOrderListByCompanyDocument, variables),
      options
    );
export const useInfinitePayrollOrderListByCompanyQuery = <
      TData = PayrollOrderListByCompanyQuery,
      TError = unknown
    >(
      variables: PayrollOrderListByCompanyQueryVariables,
      options?: UseInfiniteQueryOptions<PayrollOrderListByCompanyQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<PayrollOrderListByCompanyQuery, TError, TData>(
      ['payrollOrderListByCompany.infinite', variables],
      (metaData) => gqlOp<PayrollOrderListByCompanyQuery, PayrollOrderListByCompanyQueryVariables>(PayrollOrderListByCompanyDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      options
    )};
