import { HTMLProps } from 'react';

import classNames from 'classnames';
import { isString } from 'lodash';

import { replaceJSX } from '@/utils/react';

export interface LabelProps extends HTMLProps<HTMLLabelElement> {
  children: any;
}

const Dot = () => <span className="text-red-500 mr-2">*</span>;

export const Label = ({ className, children, ...props }: LabelProps) => (
  <label
    className={classNames(
      'text-sm leading-5 font-normal text-gray-700',
      className,
    )}
    {...props}
  >
    {isString(children) && children.includes('*')
      ? replaceJSX(children, '*', <Dot />)
      : children}
  </label>
);

Label.Dot = Dot;
