import { upperFirst } from 'lodash';
import { ErrorOption } from 'react-hook-form';

interface ErrorProps {
  checkEmpty?: boolean;
  value?: ErrorOption;
}

export const Error = ({ value, checkEmpty = false }: ErrorProps) => {
  const message = value?.message;
  if (checkEmpty && !message) return null;
  return (
    <div
      role={message ? 'alert' : 'presentation'}
      className="text-xs mt-1 h-6 text-red-500"
    >
      {message && upperFirst(message)}
    </div>
  );
};
